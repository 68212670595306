import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, SelectChangeEvent } from '@mui/material';
import {
	DataGrid,
	GridCellParams,
	GridPaginationModel,
	GridRenderCellParams,
	GridSortDirection,
	GridSortModel,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ROUTES } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	getSingleReceiptsIsLoadingSelector,
	getSingleReceiptsList,
	getSingleReceiptsListSelector,
} from 'store/single-receipts';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { IQuery, ISingleReceiptsFilters } from 'types';
import { CustomDatePicker, CustomSelect } from 'components/elements';
import { PAGINATION_LIMIT, PAYMENT_STATUS_TITLE, SINGLE_RECEIPTS_HEADER_TITLES } from 'utils/constants';
import { DATE_FORMAT, PAYMENT_STATUS_FILTER, SINGLE_RECEIPTS_FIELDS_NAME, SORT_DIR } from 'utils/enums';
import { convertCoinsToUAH, formatQueryString, getLocaleAmountString, parseQueryString } from 'utils/helpers';
import { formatDateView } from 'utils/helpers/date';
import { getTableSetting } from 'utils/helpers/table-setting';
import {
	COLUMNS_CONFIG,
	disablePastYear,
	downloadReceipt,
	getCellClassName,
	PAYMENT_STATUS_OPTIONS,
	shouldDisableMonth,
} from './config';
import styles from './index.module.scss';

export const SingleReceiptsPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const [filters, setFilters] = useState<ISingleReceiptsFilters>({
		status: query.status || undefined,
		date: query.date || undefined,
	});
	const singleReceiptsList = useAppSelector(getSingleReceiptsListSelector);
	const isLoading = useAppSelector(getSingleReceiptsIsLoadingSelector);

	const requestBody = useMemo(() => {
		const baseRequestBody = {
			pagination: {
				offset: (query.page || 0) * (query.pageSize || 0),
				limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			},
			sorting: {
				field: query.field || SINGLE_RECEIPTS_FIELDS_NAME.ADDRESS,
				direction: query.sort || SORT_DIR.ASC,
			},
			search: query.searchQuery || undefined,
		};
		const filteredRequestBody = {
			...baseRequestBody,
			...(filters.status && filters.date
				? {
						status: filters.status,
						date: filters.date,
				  }
				: {}),
		};

		return filteredRequestBody;
	}, [query]);

	useEffect(() => {
		dispatch(getSingleReceiptsList(requestBody));
	}, [requestBody, dispatch]);

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.singleReceipts, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.singleReceipts));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,

					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
					page: 0,
				}),
			},
			{ replace: true }
		);
	};

	const modifiedSingleReceipts = useMemo(
		() =>
			singleReceiptsList?.results.map((receipt) => ({
				...receipt,
				city: receipt.apartmentLocation ? receipt.apartmentLocation?.city : '',
				address: receipt?.apartmentLocation?.flat
					? `${receipt?.apartmentLocation?.address}, кв. ${receipt?.apartmentLocation?.flat}`
					: `${receipt?.apartmentLocation?.address || ''}`,
				status:
					receipt.status === PAYMENT_STATUS_FILTER.PAID
						? PAYMENT_STATUS_TITLE.PAID
						: receipt.status === PAYMENT_STATUS_FILTER.UNPAID
						? PAYMENT_STATUS_TITLE.UNPAID
						: '',
				createdAt: formatDateView(receipt?.apartmentLocation?.createdAt, DATE_FORMAT.DATE_FULL),
				updatedAt: formatDateView(receipt?.apartmentLocation?.updatedAt, DATE_FORMAT.DATE_FULL),
			})),
		[singleReceiptsList]
	);

	const handleButtonClick = () => {
		navigate(
			{
				search: formatQueryString({
					...query,
					status: filters.status,
					date: filters.date,
				}),
			},
			{ replace: true }
		);
	};

	const handleFiltersChange = (event: SelectChangeEvent) => {
		setFilters({ ...filters, [event.target.name]: event.target.value });
	};

	const handleDateChange = (date: Date | null, name: string) => {
		setFilters({
			...filters,
			[name]: date || null,
		});
	};

	const handleCellClick = async (params: GridCellParams, event: MuiEvent<MouseEvent>) => {
		if ((event.defaultMuiPrevented = params.field === 'download')) {
			downloadReceipt(params.row);
			event.stopPropagation();
		}
	};

	const handleClickEdit = (params: GridRenderCellParams) => {
		navigate(`/${ROUTES.singleReceipts}/${params.id}`);
	};

	return (
		<>
			<Box className={styles.tableActions}>
				<Box className={styles.tableActions__items}>
					<CustomDatePicker
						views={['year', 'month']}
						format={DATE_FORMAT.DATE_MONTH_YEAR}
						shouldDisableMonth={shouldDisableMonth}
						shouldDisableYear={disablePastYear}
						name="date"
						className={styles.date__input}
						onChange={(date) => handleDateChange(date, 'date')}
					/>
					<CustomSelect
						className={styles.tableActions__status}
						placeholder={SINGLE_RECEIPTS_HEADER_TITLES.STATUS}
						options={PAYMENT_STATUS_OPTIONS}
						onChange={handleFiltersChange}
						value={filters.status || ''}
						name={SINGLE_RECEIPTS_FIELDS_NAME.STATUS}
					/>
					<Button variant="contained" disabled={!(filters.status && filters.date)} onClick={handleButtonClick}>
						Застосувати
					</Button>
				</Box>
			</Box>
			<Box
				className={styles.tableWrapper}
				sx={{
					'& .negative': {
						color: 'text.secondary',
					},
					'& .positive': {
						color: 'success.main',
					},
					'& .neutral': {
						color: 'text.primary',
					},
				}}
			>
				<DataGrid
					disableColumnFilter
					onColumnResize={handleColumnResize}
					apiRef={apiRef}
					disableRowSelectionOnClick
					rows={modifiedSingleReceipts || []}
					rowCount={singleReceiptsList?.total || 0}
					loading={isLoading}
					rowSelection
					onCellClick={(param, event) => handleCellClick(param, event)}
					columns={[
						...COLUMNS_CONFIG,
						{
							field: 'download',
							headerName: 'ЄК',
							sortable: false,
							headerAlign: 'center',

							align: 'center',
							width: 60,
							renderCell: () => <Download />,
						},
						{
							field: 'edit',
							headerName: ' ',
							sortable: false,
							align: 'center',
							width: 60,
							renderCell: (params) => (
								<IconButton onClick={() => handleClickEdit(params)} color="primary" aria-label="Edit">
									<Edit />
								</IconButton>
							),
						},
					]}
					getCellClassName={(params: GridCellParams) => getCellClassName(params)}
					initialState={{
						pagination: {
							paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
						},
						sorting: {
							sortModel: [
								{ field: query.field || SINGLE_RECEIPTS_FIELDS_NAME.ID, sort: query.sort as GridSortDirection },
							],
						},
					}}
					slotProps={{
						pagination: {
							labelRowsPerPage: 'Показувати :',
							page: query.page || 0,
							rowsPerPage: query.pageSize || PAGINATION_LIMIT.SMALL,
						},
					}}
					paginationMode="server"
					sortingMode="server"
					onSortModelChange={handleSortField}
					onPaginationModelChange={handleChangePage}
					className={styles.table}
					pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
				/>
			</Box>
		</>
	);
};
