import * as Yup from 'yup';

export enum RadioInputOptions {
	ETL_FILES_PARSING = 'ETL_FILES_PARSING',
	ETL_UNDEFINED_DATA = 'ETL_UNDEFINED_DATA',
	BILL_CREATE_SINGLE_RECEIPT = 'BILL_CREATE_SINGLE_RECEIPT',
	REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT = 'REPORT_CREATE_GENERATE_PDF_SINGLE_RECEIPT',
}

export interface IParserForm {
	radioValue: RadioInputOptions;
	hts: null | File;
	hvd: null | File;
	kvbo: null | File;
	jks: null | File;
}

export const validationSchema = Yup.object().shape({
	hts: Yup.mixed().nullable(),
	hvd: Yup.mixed().nullable(),
	kvbo: Yup.mixed().nullable(),
	jks: Yup.mixed().nullable(),
	radioValue: Yup.string()
		.required()
		.test('is-files-exist', 'Виберіть хоча б один файл', (value: any, context) => {
			// eslint-disable-next-line eqeqeq
			if (value == RadioInputOptions.ETL_FILES_PARSING) {
				const { hts, hvd, kvbo, jks } = context.parent;
				return hts || hvd || kvbo || jks;
			}
				return true;

		}),
});

export const initialValues: IParserForm = {
	radioValue: RadioInputOptions.ETL_FILES_PARSING,
	hts: null,
	hvd: null,
	kvbo: null,
	jks: null,
};
