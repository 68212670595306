import { ICategoriesDetails } from 'types';
import { CATEGORIES_HEADER_TITLES } from 'utils/constants';
import { CATEGORIES_FIELDS_NAME } from 'utils/enums';

export const COLUMNS_CONFIG = [
	{ field: CATEGORIES_FIELDS_NAME.ID, headerName: CATEGORIES_HEADER_TITLES.ID, sortable: false, flex: 1 },
	{ field: CATEGORIES_FIELDS_NAME.NAME, headerName: CATEGORIES_HEADER_TITLES.NAME, sortable: false, flex: 1 },
	{
		field: CATEGORIES_FIELDS_NAME.SERVICES_COUNT,
		headerName: CATEGORIES_HEADER_TITLES.SERVICES_COUNT,
		sortable: false,
		flex: 1,
		valueGetter: (_: unknown, row: ICategoriesDetails) => row?._count?.servicesCategoriesMap ?? '',
	},
	{
		field: CATEGORIES_FIELDS_NAME.CREATED_AT,
		headerName: CATEGORIES_HEADER_TITLES.CREATED_AT,
		sortable: false,
		flex: 1,
	},
	{
		field: CATEGORIES_FIELDS_NAME.SEQUENCE_NUMBER,
		headerName: CATEGORIES_HEADER_TITLES.SEQUENCE_NUMBER,
		sortable: false,
		flex: 1,
	},
];
