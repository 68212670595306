import { FC, useContext, useEffect, useState } from 'react';
import { Box, Divider, IconButton, Link, Typography } from '@mui/material';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import SocketContext from 'socket/ReportsSocket/context';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	deleteReportById,
	getReportsList,
	getReportsLoadingSelector,
	getReportsSelector,
	resetReportsList,
} from 'store/reports';
import { IReportData, IReportsRequest } from 'types';
import { Observer } from 'components/modules/Observer';
import toastService from 'components/elements/Toastify';
import { DEFAULT_PAGINATED_REPORTS_LIST_REQUEST_BODY } from 'utils/constants';
import { DATE_FORMAT, REPORT_STATUS } from 'utils/enums';
import { formatDateView } from 'utils/helpers/date';
import { getErrorMessage } from 'utils/helpers';
import styles from './index.module.scss';

export const ReportList: FC = () => {
	const [isReportsLoaded, setIsReportsLoaded] = useState(false);
	const { generate } = useContext(SocketContext);
	const isLoading = useAppSelector(getReportsLoadingSelector);
	const reportsList = useAppSelector(getReportsSelector);
	const dispatch = useAppDispatch();

	const [reqBody, setReqBody] = useState<IReportsRequest>({
		...DEFAULT_PAGINATED_REPORTS_LIST_REQUEST_BODY,
	});

	const handleCompleteReportGeneration = async () => {
		setIsReportsLoaded(false);
		try {
			await dispatch(
				getReportsList({
					...DEFAULT_PAGINATED_REPORTS_LIST_REQUEST_BODY,
					pagination: {
						offset: reportsList.length,
						limit: 8,
					},
				})
			).unwrap();
		} catch (error) {
			toastService.error(getErrorMessage(error));
		}
	};

	useEffect(() => {
		if (generate === REPORT_STATUS.COMPLETE) {
			handleCompleteReportGeneration();
		}
	}, [dispatch, generate]);

	useEffect(() => {
		dispatchGetReportsList();
	}, [reqBody]);

	useEffect(() => {
		return () => {
			dispatch(resetReportsList());
		};
	}, []);

	const handleDelete = async (report: IReportData) => {
		try {
			await dispatch(deleteReportById(report.id)).unwrap();
		} catch (error) {
			console.log('Error deleting report :', error);
		}
	};

	const dispatchGetReportsList = async () => {
		try {
			const responseList = await dispatch(getReportsList(reqBody)).unwrap();
			if (!responseList.length) setIsReportsLoaded(true);
		} catch (error) {
			toastService.error(getErrorMessage(error));
		}
	};

	const handleObserve = (): void => {
		setReqBody((prev) => ({
			...prev,
			pagination: { ...prev.pagination, offset: reportsList.length },
		}));
	};
	return (
		<Box className={styles.content}>
			<Typography variant="h4" mb={3}>
				Сформовані звіти
			</Typography>
			<Box className={styles.content__reportsList}>
				{reportsList?.length ? (
					<>
						{reportsList.map((report: IReportData) => (
							<Box key={report?.id}>
								<Divider />
								<Box sx={{ padding: '12px 0' }}>
									<Typography variant="body2">{report.name}</Typography>
									<Box className={styles.content__reportItem}>
										<Typography variant="body2" color="common.textLightGray">
											{formatDateView(report.createdAt, DATE_FORMAT.DATE_TIME)}
										</Typography>
										<Box>
											<IconButton onClick={() => handleDelete(report)}>
												<TrashIcon />
											</IconButton>
											<Link component="a" href={report.s3URL} download={report.name}>
												<IconButton>
													<Download />
												</IconButton>
											</Link>
										</Box>
									</Box>
								</Box>
							</Box>
						))}
						{!isReportsLoaded && <Observer onObserve={handleObserve} isLoading={isLoading} />}
					</>
				) : (
					<Typography variant="h5" color="common.textLightGray" margin="0 auto">
						Тут з'являться сформовані звіти
					</Typography>
				)}
			</Box>
		</Box>
	);
};
