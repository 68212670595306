import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { IReportSingleReceiptResponse, ISingleReceiptsDetails } from 'types';
import { PAYMENT_STATUS_TITLE, SINGLE_RECEIPTS_HEADER_TITLES } from 'utils/constants';
import { PAYMENT_STATUS_FILTER, SINGLE_RECEIPTS_FIELDS_NAME } from 'utils/enums';
import { ReportsService } from 'utils/services';
import toastService from '../../components/elements/Toastify';

export const COLUMNS_CONFIG = [
	{
		field: SINGLE_RECEIPTS_FIELDS_NAME.APARTMENT_ACCOUNT_ID,
		headerName: SINGLE_RECEIPTS_HEADER_TITLES.APARTMENT_ACCOUNT_ID,
		sortable: false,
		flex: 0.9,
	},

	{
		field: SINGLE_RECEIPTS_FIELDS_NAME.ID,
		headerName: SINGLE_RECEIPTS_HEADER_TITLES.ID,
		flex: 0.9,
	},
	{
		field: SINGLE_RECEIPTS_FIELDS_NAME.ADDRESS,
		headerName: SINGLE_RECEIPTS_HEADER_TITLES.ADDRESS,
		flex: 2.1,
	},
	{
		field: SINGLE_RECEIPTS_FIELDS_NAME.CITY,
		headerName: SINGLE_RECEIPTS_HEADER_TITLES.CITY,
		sortable: false,
		flex: 0.6,
	},
	{
		field: SINGLE_RECEIPTS_FIELDS_NAME.STATUS,
		headerName: SINGLE_RECEIPTS_HEADER_TITLES.STATUS,
		flex: 0.8,
	},
	{
		field: SINGLE_RECEIPTS_FIELDS_NAME.CREATED_AT,
		headerName: SINGLE_RECEIPTS_HEADER_TITLES.CREATED_AT,
		flex: 1,
	},
	{
		field: SINGLE_RECEIPTS_FIELDS_NAME.UPDATED_AT,
		headerName: SINGLE_RECEIPTS_HEADER_TITLES.UPDATED_AT,
		flex: 1,
	},
];

export const PAYMENT_STATUS_OPTIONS = [
	{ title: PAYMENT_STATUS_TITLE.PAID, value: PAYMENT_STATUS_FILTER.PAID },
	{ title: PAYMENT_STATUS_TITLE.UNPAID, value: PAYMENT_STATUS_FILTER.UNPAID },
	{ title: PAYMENT_STATUS_TITLE.ALL, value: PAYMENT_STATUS_FILTER.ALL },
];

export const shouldDisableMonth = (date: dayjs.Dayjs) => {
	return date.isBefore(dayjs('2023-11'), 'month');
};

export const disablePastYear = (date: dayjs.Dayjs) => {
	return date.isBefore(dayjs('2023'), 'year');
};

export const getCellClassName = (params: any) => {
	if (params.field === SINGLE_RECEIPTS_FIELDS_NAME.BALANCE && params.value !== null) {
		return params.value === 0 || params.value > 0 ? 'positive' : params.value < 0 ? 'negative' : 'neutral';
	}
	if (params.field === SINGLE_RECEIPTS_FIELDS_NAME.STATUS && params.value !== null) {
		return params.value === PAYMENT_STATUS_TITLE.UNPAID ? 'negative' : 'positive';
	}
	return '';
};

export const downloadReceipt = async (singleReceipt: ISingleReceiptsDetails): Promise<void> => {
	try {
		const {
			data: { s3URL },
		}: AxiosResponse<IReportSingleReceiptResponse> = await ReportsService.getReportSingleReceipt({
			apartmentAccountId: singleReceipt.apartmentAccountId,
			year: singleReceipt.year,
			month: singleReceipt.month,
		});

		if (!s3URL) throw new Error('Receipt URL not found.');

		const { data: receiptBlob } = await axios.get(s3URL, { responseType: 'blob' });
		const blobUrl = URL.createObjectURL(receiptBlob);
		const downloadLink = document.createElement('a');
		downloadLink.href = blobUrl;
		downloadLink.download = `${singleReceipt.apartmentAccountId}.pdf`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		window.open(blobUrl, '_blank');
		document.body.removeChild(downloadLink);
		URL.revokeObjectURL(blobUrl);
	} catch (error) {
		toastService.error(error.response.data.errorData.message);
		console.error('Error downloading receipt:', error);
	}
};
