import axios from 'axios';
import { IDetailsItem, IReportsDataList } from 'types';
import { SINGLE_RECEIPT_DETAILS_KEYS } from 'utils/enums';

export const DETAILS_TITLES: IDetailsItem<SINGLE_RECEIPT_DETAILS_KEYS>[] = [
	{
		title: 'Місто',
		key: SINGLE_RECEIPT_DETAILS_KEYS.CITY,
		modalTitle: 'Вкажіть ваше ',
	},
	{
		title: 'Адреса',
		key: SINGLE_RECEIPT_DETAILS_KEYS.STREET,
		modalTitle: 'Вкажіть вашу ',
	},
	{
		title: 'Номер ЄК',
		key: SINGLE_RECEIPT_DETAILS_KEYS.ID,
		modalTitle: 'Вкажіть ваш ',
	},
	{
		title: 'Баланс',
		key: SINGLE_RECEIPT_DETAILS_KEYS.BALANCE,
		modalTitle: 'Вкажіть  ваш ',
	},
];

export const downloadReportPdf = async (report: IReportsDataList) => {
	if (report) {
		try {
			const response = await axios.get(report.s3URL, { responseType: 'blob' });
			const blobUrl = URL.createObjectURL(response.data);
			const downloadLink = document.createElement('a');
			downloadLink.href = blobUrl;
			downloadLink.download = `${report.apartmentAccountId}.pdf`;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			window.open(blobUrl, '_blank');
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(blobUrl);
		} catch (error) {
			console.log('Error download report :', error);
		}
	}
};
